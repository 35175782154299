import * as React from 'react';
import reject from 'lodash/fp/reject';
import flatten from 'lodash/fp/flatten';

import { Loadable, RequestProps } from '@kwara/components/src/Loadable';
import { Date, Text, Time } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { TransactionItemType } from '@kwara/components/src/TransactionItemType';
import { SavingsTransactionType, SavingType, MemberType, SavingsTransaction } from '@kwara/models/src';
import { Checkbox } from '@kwara/components/src/Form';
import { If } from '@kwara/components/src/If/If';

import { savingPath } from '../../../lib/urls';
import { AccountList } from './AccountList';
import { SavingsAccountCard } from './SavingsAccountCard';
import DetailSubsection from '../../../components/DetailSubsection';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';
import { Currency } from '../../../components/Currency';
import { AppPermissions } from '../../../models/Permission';
import TransactionAdjustment from '../../../components/TransactionAdjustment';
import { shouldHideRow } from '../../../components/TransactionAdjustment/utils';
import { AdjustmentButtonsPropTypes } from '../../../components/TransactionAdjustment/AdjustmentButtons';

import styles from './Savings.module.scss';

function renderRow(
  transaction: SavingsTransactionType,
  showAdjustedTransactions: boolean,
  AdjustmentButtons: React.FunctionComponent<AdjustmentButtonsPropTypes>
) {
  const hideRow = shouldHideRow(showAdjustedTransactions, transaction);
  return (
    <If
      key={transaction.id}
      condition={!hideRow}
      do={
        <Row className={transaction.isAdjustment || transaction.wasAdjusted ? 'grey-300' : ''}>
          <Cell>
            <div className="grey-300">{transaction.id}</div>
            <div>
              <Date value={transaction.createdAt} />
            </div>
            <div>
              <Time value={transaction.createdAt} />
            </div>
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.account.id}</div>
            <div>{transaction.account.product.name}</div>
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.reference}</div>
            <TransactionItemType transaction={transaction} />
            {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
          </Cell>
          <Cell align="right">
            <Currency value={transaction.amount} />
          </Cell>
          <Cell align="right">
            <Currency value={transaction.balance} />
          </Cell>
          <Cell align="right">
            <AdjustmentButtons transaction={transaction} />
          </Cell>
          <Cell />
        </Row>
      }
    />
  );
}
type Props = {
  member: MemberType;
  showClosedSavings: boolean;
  savingsR: RequestProps<SavingType[]>;
};

export function Savings(props: Props) {
  const { showClosedSavings, savingsR, member } = props;
  const [showAdjustedTransactions, setShowAdjustedTransactions] = React.useState(false);

  const heading = (
    <Row>
      <Heading translationId="TransactionsTable.date" />
      <Heading className={styles.AccountColumn} translationId="TransactionsTable.account" />
      <Heading width="150px" translationId="TransactionsTable.type" />
      <Heading width="120px" align="right" translationId="TransactionsTable.amount" />
      <Heading align="right" translationId="TransactionsTable.balance" />
      <Heading width="175px" align="right" translationId="TransactionsTable.actions" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <Loadable {...savingsR}>
      {(savings: SavingType[]) => {
        const accounts: SavingType[] = showClosedSavings ? savings : reject(saving => saving.isClosed(), savings);
        const transactions = flatten(
          accounts.map((saving: SavingType) =>
            saving.transactions.map((transaction: SavingsTransactionType) => {
              transaction.account = saving;
              return transaction;
            })
          )
        );

        return (
          <TransactionAdjustment.Root adjustTransactionsPermission={AppPermissions.AdjustSavingsTransactions}>
            <TransactionAdjustment.AdjustmentModals query={savingsR} spraypaintModel={SavingsTransaction} />
            <DetailSubsection title={<Text id="MemberDetail.accounts" />}>
              <AccountList
                accounts={accounts}
                columns={3}
                width="w-33"
                actionButton={
                  <Button
                    className="mt2"
                    type="primary"
                    to={savingPath({ action: 'create', memberId: member.id }, { asObject: true })}
                  >
                    <Text id="MemberDetail.accounts.Savings.Button.add" />
                  </Button>
                }
                renderAccount={(account: SavingType) => (
                  <SavingsAccountCard key={account.id} account={account} to={savingPath({ id: account.id })} />
                )}
              />
            </DetailSubsection>
            <DetailSubsection title={<Text id="MemberDetail.transactions" />}>
              <Checkbox
                name="showAdjustedSavingTransactions"
                labelId="MemberDetail.Transactions.Subsection.Checkbox.showAdjusted"
                onChange={() => setShowAdjustedTransactions(prev => !prev)}
              />
              <TransactionsTable
                heading={heading}
                renderRow={(transaction: SavingsTransactionType) =>
                  renderRow(transaction, showAdjustedTransactions, TransactionAdjustment.AdjustmentButtons)
                }
                transactions={transactions}
                maximumEntries={100}
              />
            </DetailSubsection>
          </TransactionAdjustment.Root>
        );
      }}
    </Loadable>
  );
}
